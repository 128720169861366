import { Fragment, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import Input from "./Input";
import { sendDrawDownRequest } from "../service/loanService";

const Drawdown = (props) => {
    const { company, formFields, redirectToStep, onboarding_data } = props;
    const formRef = useForm();
    const max_amount = onboarding_data.max_amount;
    const onboarding_id = onboarding_data.onboarding_id;

    // Get drawdown fields and modify the drawdown amount validation
    const drawdown_fields = formFields[company]?.filter(
        (field) => field?.page === "drawdown-details"
    ).map(field => {
        if (field.name === 'drawdown_amount') {
            return {
                ...field,
                label: `Enter Drawdown Amount. Maximum amount available: ₹${max_amount}`,
                validation: {
                    ...field.validation,
                    max: {
                        value: max_amount,
                        message: `Amount cannot exceed ${max_amount}`
                    }
                }
            };
        }
        return field;
    });

    const {
        reset,
        handleSubmit,
        trigger,
        // Read the formState before render to subscribe the form state through the Proxy
        formState: { isDirty, isValid },
    } = formRef;
    const handleDrawdownSubmit = handleSubmit(async (data) => {
        const req_data = {
            "event_type": "post_drawdown",
            "onboarding_id": onboarding_id,
            "amount_requested": Number(data.drawdown_amount)
        }
        const post_drawdown_data = await sendDrawDownRequest(req_data);
        redirectToStep(data,post_drawdown_data);
    });
    return (
        <div className="flex flex-col items-center justify-center p-8">
            <div className="text-2xl text-[#4D4D4D] font-sans font-bold mb-4">
                Request Drawdown
            </div>
            <div className="text-center text-[#666666] max-w-md mb-4">
                Your onboarding is complete. You can now request for drawdown.
                <div className="font-semibold mt-2">
                    Maximum amount available: ₹{max_amount}
                </div>
            </div>
            <FormProvider {...formRef}>
                <form
                    onSubmit={(e) => e.preventDefault()}
                    noValidate
                    autoComplete="off"
                    className="my-5 px-5"
                >
                    <div className="max-h-[500px] overflow-y-auto">
                        {drawdown_fields?.map((element, index) => (
                            <Fragment key={`${element.name}-${index}`}>
                                <Input {...element} />
                            </Fragment>
                        ))}
                    </div>

                    <div className="my-7 mx-5 flex items-center justify-center">
                        <button
                            onClick={handleDrawdownSubmit}
                            disabled={!isDirty || !isValid}
                            className="bg-[#4E05FF] text-white text-base py-2 px-3 rounded disabled:opacity-50"
                        >
                            Continue
                        </button>
                    </div>
                </form>
            </FormProvider>
        </div>
    );
};

export default Drawdown; 