import { useEffect, useState } from "react";
import Header from "../components/Header";
import whatsapp from "../assets/icons/whatsapp.svg";

const TWSuccessPage = ({ applicationId }) => {

  return (
    <>
      {/* <Header hidePartnerLogo={true} /> */}
      <div className="text-xl text-[#4D4D4D] font-sans font-bold my-7 px-5">
        Your application has been successfully registered with us
      </div>
      <div className="text-xl text-[#4D4D4D] font-sans font-bold my-7 px-5">
        Your Application Number - {applicationId || ""}
      </div>
      <div className="text-xl text-[#4D4D4D] font-sans font-bold my-7 px-5">
        Our team will reach out to you soon. They will help you finalise the vehicle and support you with the complete loan processing.
      </div>
      <div className="text-xl text-[#4D4D4D] font-sans font-bold my-7 px-5">
        हमारी टीम जल्द ही आप तक पहुंचेगी। वे आपको वाहन चुनने में मदद करेंगे और संपूर्ण लोन प्रक्रिया में आपकी सहायता करेंगे।
      </div>
      <div className="my-5 px-5">
        <div className="text-base text-[#4D4D4D] font-sans">

        </div>
        {/* <div className="text-base font-sans text-[#404040] font-medium">
          {applicationId || ""}
        </div> */}
      </div>
    </>
  );
};

export default TWSuccessPage;
