import React from 'react';

const RequestInProcess = () => {
    return (
        <div className="flex flex-col items-center justify-center p-8">
            <div className="text-2xl text-[#4D4D4D] font-sans font-bold mb-4">
                Request In Process
            </div>
            <div className="text-center text-[#666666] max-w-md">
                Your request is currently being processed. Please wait while we review your application.
                You will be notified once the process is complete.
            </div>
        </div>
    );
};

export default RequestInProcess; 