import { useState } from "react";
import { useLocation } from "react-router-dom";
import { Stepper, Step } from "react-form-stepper";
import { toast } from "react-toastify";
import { formFields } from "../constants/formFields";
import Header from "../components/Header";
import MobileVerification from "../components/MobileVerification";
import PersonalDetails from "../components/PersonalDetails";
import SelfieCapture from "../components/SelfieCapture";
import LoanDetails from "../components/LoanDetails";
import MobileNumberAlreadyRegistered from "../components/MobileNumberAlreadyRegistered";
import Terms from "../components/Terms";
import TWSuccessPage from "../components/TWSuccessPage";
import Loader from "../components/Loader";
import step_active from "../assets/icons/step_active.svg";
import step_completed from "../assets/icons/step_completed.svg";
import step_inactive from "../assets/icons/step_inactive.svg";
import {
  loanFormCheckStatusApiCallHandler,
  sendLoanRequest,
  sendSelfieRequest,
  sendAdhaarConsent,
  saveAadhaarLivenessResponseHandler,
  sendTwoWheelerLoanRequest
} from "../service/loanService";
import "../style.css";
import { useEffect } from "react";
import { twformFields } from "../constants/twformFields";
import axios from "axios";

export default function TWLoanPage() {
  const [isLoading, setLoader] = useState(false);
  const [isMounted, setMounted] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [loanData, setLoanData] = useState(null);
  const [applicationId, setApplicationId] = useState("");
  const [consentUrl, setconsentUrl] = useState("");
  const [preSelfieApplicationId, setpreSelfieApplicationId] = useState("");
  const [verifyPhoneData, setVerifyPhoneData] = useState(null);
  const search = useLocation().search;
  const company =  new URLSearchParams(search).get("partner") || "all";
  const emp_id = new URLSearchParams(search).get("emp_id") || "";
  let fieldsState = {};
  if (formFields && company) {
    formFields[company]?.forEach((field) => (fieldsState[field.name] = ""));
  }

  useEffect(() => {
    const _data = JSON.parse(localStorage.getItem(`${company}-${emp_id}`));
    // if (
    //   _data?.activeStep !== "" &&
    //   _data?.activeStep !== undefined &&
    //   _data?.activeStep !== null
    // ) {
    //   setActiveStep(Number(_data?.activeStep));
    // }
    if (_data?.loanData) {
      setLoanData(_data?.loanData);
    }
    if ((_data?.activeStep !== "" || _data?.activeStep !== undefined || _data?.activeStep !== null) && company == "chaloNetwork") {
      setActiveStep(1);
    }

    setMounted(true);
  }, []);

  useEffect(() => {
    if (isMounted) {
      localStorage.setItem(
        `${company}-${emp_id}`,
        JSON.stringify({ loanData: loanData })
      );
    }
  }, [activeStep, loanData, company, emp_id]);

  const redirectToPersonalDetailPage = async (_data) => {
    try {
      const _request = {
        mobile_number: `${_data?.mobile_number}`,
        whatsapp_number: `${_data?.whatsapp_number}`,
        company_employee_id: emp_id || "OPTIONAL",
        company: company || "",
        url: window.location.href
      };
      setLoader(true);
    //   const result = await loanFormCheckStatusApiCallHandler(_request);
    //   const { status, description, message, employee } = result;
      setLoanData({
        ...loanData,
        ..._data,
      });
      //   ...employee,
      setActiveStep(1);
    //   if (status === "success") {
    //     if (description && message) {
    //       setVerifyPhoneData({ ...result });
    //     } else {
    //       setLoanData({
    //         ...loanData,
    //         ..._data,
    //       });
    //       //   ...employee,
    //       setActiveStep(1);
    //     }
    //   }
    } catch (err) {
      toast.error("Something went wrong!");
    } finally {
      setLoader(false);
    }
  };

  const redirectToLoanDetailPage = async (_personalDetails) => {
    try {
        setLoader(true)
        const data = {...loanData, ..._personalDetails, company:company};
        const save_twl_response = await sendTwoWheelerLoanRequest(data);
        // console.log('save response', save_twl_response)
        if(save_twl_response.request_id){
            setApplicationId(save_twl_response.request_id)
        }
    } catch (error) {
        console.log('error',error)
        toast.error('Internal Server Error. Please try again later.')
    }
    finally{
        setLoader(false);
    }
  };

  const redirectToTermsDetailPage = (_loanData) => {
    setLoanData({
      ...loanData,
      ..._loanData,
    });
    setActiveStep(3);
  };

  const saveSelfieData = async (url) => {
    try {
      setLoader(true);
      const selfieLambdaResponse = await sendSelfieRequest({
        "request_type": "lending-form-selfie",
        "img_url": url,
        "emp_id": preSelfieApplicationId || Math.random().toString(36).substring(2, 11),
        "aadhaarApiResponse": loanData.aadhaarApiResponse
      })
      console.log('selfieLambdaResponse', selfieLambdaResponse)
      if (preSelfieApplicationId && selfieLambdaResponse.body.approved) {
        setApplicationId(preSelfieApplicationId);
        const saveAadhaarLivenessResponse = await saveAadhaarLivenessResponseHandler(
          {
            "img_url": url,
            "emp_id": preSelfieApplicationId,
            "aadhaarApiResponse": loanData.aadhaarApiResponse,
            "response_face_liveness": selfieLambdaResponse.body.response_face_liveness,
          }
        )

      }
      else {
        setApplicationId(Math.random().toString(36).substring(2, 11));
        // toast.error("Something went wrong!");
      }
    } catch (error) {
      console.log(error)
    }
    finally{
      setLoader(false);
    }
    // setActiveStep(4);
  };

  const saveLoanDetails = async () => {
    try {
      let data = { ...loanData };
      if (company !== "entitled" && company !== "enableCap") {
        data["company_name"] = company;
      }
      if (company === "entitled" || company === "EverestFleet") {
        data["common_form"] = true;
      } else {
        data["common_form"] = false;
      }
      if (company === "quess") {
        data["company_employee_id"] = emp_id;
      }
      if (company.toLowerCase() === "sis") {
        data["emp_id"] = emp_id;
      }
      delete data.otp;
      setLoader(true);
      const result = await sendLoanRequest(data);
      const { status, id } = result;
      if (status === "success") {
        window.gtag("event", "submission_success", { employee_id: emp_id });
        setActiveStep(4);
        setpreSelfieApplicationId(id);
        localStorage.removeItem(`${company}-${emp_id}`);
      } else {
        window.gtag("event", "submission_error", { employee_id: emp_id });
      }
      let consentResponse =  await sendAdhaarConsent({ "request_type":"consent",request_id:id})
      if(consentResponse.success){
        setconsentUrl(consentResponse.url)
      }
    } catch (err) {
      toast.error("Something went wrong!");
    } finally {
      setLoader(false);
    }
  };

  const goToHomeScreen = () => {
    setVerifyPhoneData(null);
    setLoanData(null);
  };

  const renderLoadForm = () => {
    switch (activeStep) {
      case 0:
        return (
          <>
            {verifyPhoneData ? (
              <MobileNumberAlreadyRegistered
                {...verifyPhoneData}
                goToHomeScreen={goToHomeScreen}
              />
            ) : (
              <MobileVerification
                company={company}
                redirectToStep={redirectToPersonalDetailPage}
              />
            )}
          </>
        );
      case 1:
        return (
          <PersonalDetails
            company={company}
            formFields={twformFields}
            loanData={loanData}
            redirectToStep={redirectToLoanDetailPage}
          />
        );
    //   case 2:
    //     return (
    //       <LoanDetails
    //         company={company}
    //         formFields={formFields}
    //         redirectToStep={redirectToTermsDetailPage}
    //       />
    //     );
    //   case 3:
    //     return (
    //       <Terms
    //         company={company}
    //         formFields={formFields}
    //         loanData={loanData}
    //         redirectToStep={saveLoanDetails}
    //       />
    //     );
    //   case 4:
    //     return (
    //       <SelfieCapture
    //         company={company}
    //         // formFields={formFields}
    //         redirectToStep={saveSelfieData}
    //       />
    //     );
      default:
        break;
    }
  };
  const getStepImg = (_fromStep) => {
    if (activeStep === _fromStep) {
      return step_active;
    } else if (activeStep > _fromStep) {
      return step_completed;
    } else if (activeStep < _fromStep) {
      return step_inactive;
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <Header hidePartnerLogo={true}/>
      {!applicationId ? (
        <>
          <Stepper activeStep={activeStep} className="!p-0 loan-stepper my-3">
            <Step
              label="Step 1"
              className="custom-step"
              style={{
                backgroundImage: `URL(${getStepImg(0)})`,
                backgroundSize: "cover",
                backgroundColor: "unset",
              }}
            />
            <Step
              label="Step 2"
              className="custom-step"
              style={{
                backgroundImage: `URL(${getStepImg(1)})`,
                backgroundSize: "cover",
                backgroundColor: "unset",
              }}
            />
            {/* <Step
              label="Step 3"
              className="custom-step"
              style={{
                backgroundImage: `URL(${getStepImg(2)})`,
                backgroundSize: "cover",
                backgroundColor: "unset",
              }}
            />
            <Step
              label="Step 4"
              className="custom-step"
              style={{
                backgroundImage: `URL(${getStepImg(3)})`,
                backgroundSize: "cover",
                backgroundColor: "unset",
              }}
            />
            <Step
              label="Step 4"
              className="custom-step"
              style={{
                backgroundImage: `URL(${getStepImg(4)})`,
                backgroundSize: "cover",
                backgroundColor: "unset",
              }}
            /> */}
          </Stepper>
          {renderLoadForm()}
        </>
      ) : (
        <TWSuccessPage applicationId={applicationId}/>
      )}
    </>
  );
}
