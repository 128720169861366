import { useEffect, useState } from "react";
import Header from "../components/Header";
import whatsapp from "../assets/icons/whatsapp.svg";

const EWASuccessPage = ({ applicationId }) => {

  return (
    <>
      {/* <Header hidePartnerLogo={true} /> */}
      <div className="text-xl text-[#4D4D4D] font-sans font-bold my-7 px-5">
        Your application has been successfully registered with us
      </div>
      <div className="my-5 px-5">
        <div className="text-base text-[#4D4D4D] font-sans">
          Your Application Number
        </div>
        <div className="text-base font-sans text-[#404040] font-medium">
          {applicationId || ""}
        </div>
      </div>
    </>
  );
};

export default EWASuccessPage;
